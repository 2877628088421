:root {
    --primary-color           : #000f2e;
    --secondary-color         : #0881fb;
    --third-color             : #EDA700;
    --fourth-color            : #244680;
    --light-black             : #303030;
    --white-color             : #ffffff;
    --gray-color              : #e8e8e8;
    --black-color             : #000000;
    --spacing-between-div     : 2.8rem;
    --fz-paragraph            : 1.8rem;
    --fz-h1                   : 5.6rem;
    --fz-xs-h1                : 4.0rem;
    --fz-h2                   : 3.5rem;
    --fz-h3                   : 3.0rem;
    --fz-h4                   : 2.8rem;
    --fz-h5                   : 2.6rem;
    --fz-h6                   : 2.3rem;
}

* {
    box-sizing: border-box;
    margin    : 0;
    padding   : 0;
}

html {
    font-size: 62.5%;
}

body {
    font-size  : 1.8rem;
    min-width  : 350px;
    font-family: 'Geogrotesque_Rg', Arial, Helvetica, sans-serif;
}

p, a{
    margin-bottom: 1.4rem;
    line-height: 2.6rem;
}

h1{
    font-size: var(--fz-xs-h1);
    margin-bottom: 1.6rem;
    font-family: 'Geogrotesque_SmBd', Arial, Helvetica, sans-serif;
}

h2{
    font-size: var(--fz-h2);
    margin-bottom: 1.6rem;
    font-family: 'Geogrotesque_SmBd', Arial, Helvetica, sans-serif;
}

h3{
    font-size: var(--fz-h3);
    margin-bottom: 1.6rem;
    font-family: 'Geogrotesque_SmBd', Arial, Helvetica, sans-serif;
}

h4{
    font-size: var(--fz-h4);
    margin-bottom: 1.6rem;
    font-family: 'Geogrotesque_SmBd', Arial, Helvetica, sans-serif;
}

h5{
    font-size: var(--fz-h5);
    margin-bottom: 1.6rem;
    font-family: 'Geogrotesque_SmBd', Arial, Helvetica, sans-serif;
}

h6{
    font-size: var(--fz-h6);
    margin-bottom: 1.6rem;
    font-family: 'Geogrotesque_SmBd', Arial, Helvetica, sans-serif;
}
ul{
    line-height: 2.8rem;
}
img.alignright {float:right; margin:0 0 1em 1em}
img.alignleft {float:left; margin:0 1em 1em 0}
img.aligncenter {display: block; margin-left: auto; margin-right: auto}
a img.alignright {float:right; margin:0 0 1em 1em}
a img.alignleft {float:left; margin:0 1em 1em 0}
a img.aligncenter {display: block; margin-left: auto; margin-right: auto}

.container {
    width        : 100%;
    padding-right: 2.5rem;
    padding-left : 2.5rem;
    margin-right : auto;
    margin-left  : auto;
}

.img-fluid {
    max-width: 100%;
    height   : auto;
}

.grid-6 {
    display              : grid;
    grid-template-columns: 1fr;
    row-gap              : var(--spacing-between-div);
}
.grid-4{
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--spacing-between-div);
}

.align-items-center{    
    align-items: center;
}

.mb-3{
    margin-bottom: 1.8rem;
}

.my-28{
    margin: 2.8rem 0rem;
}

.mb-28{
    margin-bottom: 2.8rem;
}

.py-8{
    padding: 8.6rem 0;
}

.form-label{
    color: var(--white-color);
}
.wpcf7-list-item-label{
    color: var(--white-color);
}
.text-black-form{
    .wpcf7-list-item-label{
        color: var(--black-color);
    }
}
.text-center{
    text-align: center;
}

.text-left{
    text-align: left!important;
}
.text-right{
    text-align: right!important;
}

.btn-primary{
    border: 1px solid transparent;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    line-height: 1.428571429;
    margin-bottom: 0;
    background: var(--secondary-color);
    color: var(--white-color);
    border-radius: 25px;
    padding: 1rem 2rem;
    user-select: none;
    white-space: nowrap;
    text-decoration: none;
}

.btn-secondary{
    border: 1px solid transparent;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    line-height: 1.428571429;
    margin-bottom: 0;
    background: var(--third-color);
    color: var(--white-color);
    border-radius: 10px;
    padding: 1rem 2rem;
    user-select: none;
    white-space: nowrap;
    text-decoration: none;
}

.float{
    display: flex;
	position:fixed;
    align-content: center;
    justify-content: center;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
  font-size:3.6rem;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
    text-decoration: none;
    i{
        line-height: initial;
    }
}

.my-float{
	margin-top:12px;
}

.btn-third{
    border: 2px solid var(--third-color);
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    line-height: 1.428571429;
    margin-bottom: 0;
    background: var(--white-color);
    color: var(--third-color);
    border-radius: 10px;
    padding: 1rem 2rem;
    user-select: none;
    white-space: nowrap;
    text-decoration: none;
}

.hidden-xs{
    display: none;
}


// Small devices 
@media (min-width: 576px) {
    h1{
        font-size: var(--fz-h1);
        margin-bottom: 1.6rem;
        font-family: 'Geogrotesque_SmBd', Arial, Helvetica, sans-serif;
    }
    .container {
        max-width: 540px;
    }
    .grid-6 {
        display              : grid;
        grid-template-columns: 1fr;
        row-gap              : var(--spacing-between-div);
    }
}

// Medium devices 
@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
    .grid-6 {
        display              : grid;
        grid-template-columns: 1fr;
        row-gap              : var(--spacing-between-div);
    }
    .grid-4{
        grid-template-columns: repeat(3, 1fr);
        gap: 2.8rem;
    }      
    .hidden-xs{
        display: block;
    }    
}

// Large devices
@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
    .grid-6 {
        display              : grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap           : var(--spacing-between-div);
    }
}

// Extra large devices
@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }     
}

// Extra Extra large devices 
@media (min-width: 1400px) {
    .container {
        max-width: 1320px;
    }
}